body {
  min-height: 100vh;
  margin: 0;
  display: grid;
  box-sizing: border-box;
  grid-template-rows: 300px auto 50px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: slategrey;
}

main {
  background-color: rgba(94, 175, 255, 0.13);
  border-top: 3px solid #A6CE39;
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #c0c0c0;
}

a {    
  text-decoration: none;
  color: slategrey;
}

a:hover {
  text-decoration: underline;
}

li {
  line-height: 1.5;
}

li:not(:last-child) {
  margin-bottom: 10px;
}

.content-block {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
}

.content-block:last-of-type {
  padding: 0;
}

.content-block:last-of-type > div:first-child {
  display: flex;
  border-bottom-left-radius: 10px;
}

.content-block:last-of-type > div:last-child {
  border-bottom-right-radius: 10px;
}

.content-block h3 {
  font-family: Arvo, Arial, Helvetica, sans-serif;
  font-size: 24px;
}

.content-block--text {
  height: 1fr;
  padding: 10px 30px;
  font-size: 18px;
}

.content-block--image {
  height: 100%;
  margin: 30px;
  border-radius: 10px;
}

.contact--heading {
  font-size: 24px;
}

.contact--detail {
  display: flex;
}

.contact--detail > .material-icons {
  margin: auto 10px auto 0;
}

.contact--detail p {
  font-size: 18px;
}

.contact--address {
  line-height: 0.5em;
}

.open-external {
  margin-left: 5px;
  font-size: 1.25em;
  vertical-align: text-bottom;
}

#hero {
  height: 200px;
  width: 100%;
  background-image: url('./images/midtown.jpeg');
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
}

#header--logo-banner {
  margin: 0 auto;
  width: 1400px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
} 

#header--logo-banner > a {
  height: 100%;
  align-self: center;
}

#header--logo-image {
  margin: 10% 0;
  height: 60%;
}

#header--logo-text {
  font-family: Arvo, Arial, Helvetica, sans-serif;
  color: slategrey;
  text-align: right;
}

#main--content {
  width: 1400px;
  height: auto;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr) 300px;
  row-gap: 50px;
  background-color: white;
  border-radius: 10px;
}

#construction-liaison--text {
  grid-column: 1 / span 2;
}

#construction-liaison--image {
  grid-column: 3 / span 1;
  background-image: url('./images/excavation.JPG');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#services--image {
  grid-column: 1 / span 1;
  grid-row: 1/ span 2;
  background-image: url('./images/TBM.JPG');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-position-x: -37.5px;
}

#services--text {
  grid-column: 2 / span 2;
}

#recent-projects--text {
  grid-column: 1 / span 1;
}

#recent-projects--image {
  grid-column: 2 / span 2;
  background-image: url('./images/grading.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -120px;
}

#contact--title {
  grid-column: 1 / span 1;
  background-color: #A6CE39;
  font-family: Arvo, Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: white;
}

#contact--title-text {
  margin: 0 auto;
  align-self: center;
}

#contact--hamilton {
  grid-column: 2 / span 1;
}

#contact--toronto, #contact--toronto a {
  grid-column: 3 / span 1;
  background-color: slategrey;
  color: white;
}

#footer--copyright {
  margin-left: calc((100vw - 1400px) / 2);
}

#footer--lura-main-website {
  margin-right: calc((100vw - 1400px) / 2);
}

::selection {
  background-color: #A6CE39;
  color: white;
}

/* Google Material Icons */

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

@media (max-width: 768px) {
  body {
      display: block;
  }

  main {
      border-top: 3px solid #A6CE39;
  }

  footer {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      border-top: none;
  }
  
  a {    
      text-decoration: none;
      color: slategrey;
  }
  
  a:hover {
      text-decoration: underline;
  }
  
  .content-block {
      display: flex;
      flex-direction: column;
      padding: 0;
  }
  
  .content-block:last-of-type {
      padding: 0;
      display: flex;
      flex-direction: column;
  }

  .content-block h3 {
      font-family: Arvo, Arial, Helvetica, sans-serif;
      font-size: 20px;
  }
  
  .content-block--text {
      padding: 10px 30px;
      font-size: 16px;
  }
  
  .content-block--image {
      height: 200px;
      width: 100%;
      margin: 0;
      padding: 0;
      border-radius: 0;
  }
  
  .contact--heading {
      margin-bottom: 10px;
      font-size: 24px;
  }
  
  .contact--detail {
      display: flex;
  }
  
  .contact--detail > .material-icons {
      margin: auto 10px auto 0;
  }
  
  .contact--detail p {
      font-size: 18px;
  }
  
  .contact--address {
      line-height: 0.5em;
  }
  
  .open-external {
      margin-left: 5px;
      font-size: 1.25em;
      vertical-align: text-bottom;
  }

  #hero {
      height: 100px;
      width: 100%;
      background-image: url('./images/midtown.jpeg');
      background-repeat: no-repeat;
      background-size: cover;
  }

  #header--logo-banner {
      padding: 0px; 
      width: 100%;
      height: 80px;
  } 
  
  #header--logo-banner > a {
      margin: 0 auto;
  }
  
  #header--logo-image {
      margin: 16px auto;
      height: 60%;
  }
  
  #header--logo-text {
      display: none;
  }

  #main--content {
      width: 100%;
      margin: 0 auto;
      row-gap: 0;
      display: flex;
      flex-direction: column;
      background-color: white;
  }

 
  #construction-liaison--image {
      background-image: url('./images/excavation.JPG');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-position-y: -60px;
  }
  
  #services--image {
      background-image: url('./images/TBM.JPG');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
  }
  
  #recent-projects--image {
      background-image: url('./images/grading.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
  }
  
  #contact--title {
      height: 100px;
      background-color: #A6CE39;
      font-family: Arvo, Arial, Helvetica, sans-serif;
      font-size: 24px;
      color: white;
      border-radius: 0;
  }
  
  #contact--title-text {
      margin: 0 auto;
      align-self: center;
  }
  
  #contact--hamilton {
      padding-bottom: 20px;
  }
  
  #contact--toronto, #contact--toronto a {
      padding-bottom: 20px;
      background-color: slategrey;
      color: white;
      border-radius: 0;
  }
  
  #footer--copyright {
      width: 100%;
      padding: 10px 0;
      margin: 0;
      text-align: center;
      border-top: 1px solid #c0c0c0;
  }
  
  #footer--lura-main-website {
      padding: 20px 0;
      font-size: 20px;
      margin: 0;
  }
}